@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.container {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 0px 20px;
  min-height: 84vh;

  @media screen and (max-width: 499px) {
    height: auto;
    padding: 0px;
  }
}

.speechBubble {
  top: -7px;
  left: 10px;
  font-size: 50px;
  position: relative;
  color: #ffffff;


  @media screen and (min-width: 700px) {
    transform: rotate(20deg);
    font-size: 100px;
    float: right;
  }
}

.formContainer {
  // padding: 20px 10px;
  max-width: 600px;

  @media screen and (max-width: 900px) {
    width: auto;
    // padding: 0px 15px;
  }
}

.headerContainer {
  padding-bottom: 20px;
  max-width: 600px;

  h1 {
    font-size: 50px;
    color: white;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  p {
    font-size: 18px;
    color: #293D43;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.1px;

    @media screen and (max-width: 499px) {
      line-height: 1.2;
    }
  }
}

.activeInput:focus {
  outline: none !important;
  border-color: rgb(255, 209, 148);
}

.paperPlane {
  padding-left: 5px;
}

.smileyFace {
  font-size: 80px;
  color: rgb(255, 251, 0);
}

.successMsg {
  font-size: 33px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.formContents {
  display: inline-block;
  flex-direction: column;
  max-width: 1900px;
  width: 100%;

  label {
    width: auto;
    font-size: 20px;
    color: white;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    padding-top: 20px;
  }

  input, textarea {
    width: 95%;
    padding: 0;
    padding-left: 15px;
    margin: 0;
    border: none;
    background: none;
    border: 2px solid #DDFFCE;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 7px;
    color: #DDFFCE;
    font-size: 20px;
  }

  textarea {
    padding-top: 15px
  }

  input {
    height: 40px;
    margin-bottom: 20px;
  }

  textarea {
    height: 100px;
    margin-bottom: 20px !important;
  }

  .buttonContainer {
    text-align: right;

    @media screen and (max-width: 900px) {
      text-align: center;
    }

    button {
      padding: 12px 25px;
      border-radius: 30px;
      font-size: 18px;
      border: none;
      background-color: #293D43;
      color: white;
      border: 2px solid white;
      cursor: pointer;
      transition: 0.2s;

      &:hover,  &:disabled {
        background: none;
        color: #293D43;
        border-color: #293D43;
      }

      &:disabled {
        cursor: wait;
      }

      p {
        margin: 0;
        margin-bottom: -2px;
      }
    }
  }
}
