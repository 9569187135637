.container {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: auto;
  justify-content: center;
  align-self: center;
  min-height: 680px;
  height: 100%;
  padding: 20px 20px 0px 20px;
}

@media only screen and (max-width: 1400px) {
  .container {
    flex-direction: column;
  }
}
