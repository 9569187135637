.container {
  background-color: rgb(255, 255, 255);
  margin-top: 5px;
  margin-bottom: 20px;
  

  @media screen and (min-width: 501px) {
    display: none !important;
  }
}

.active {
  transition: 0.5s;
  // display: block;

  .innerContainer {
    opacity: 1;
    height: 200px;
    z-index: 2;
  }

  .listItem:nth-child(1) {
    padding-top: 35px;
  }

  .listItem {
    height: 20px;
    padding: 7px 20px 7px 20px;
    pointer-events: all;
    opacity: 1;
    transition: 0.5s;
  }

  .listContainer {
    height: 190px;
    z-index: 1;
  }
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: linear;
	animation-duration: 0.1s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.listContainer {
  position: relative;
  display: block;
  list-style-type: none;
  padding: 0px 0px;
  height: 0px;
  transition: 0.5s;
	animation-name: fadeInOpacity;
  animation-timing-function: linear;
	animation-duration: 0.1s;
}

.listItem {
  pointer-events: none;
  display: relative;
  padding: 0px;
  font-size: 20px;
  text-align: center;
  height: 0px;
  transition: 0.3s;
  opacity: 0;
}

.innerContainer {
  position: relative;
  // border: 2px solid red;
  height: 0px;
  transition: 0.5s;
}

.innerContainer a {
  display: block;
  position: relative;
  cursor: pointer;
  color: black;
  font-weight: 700;

  &:hover {
    font-weight: bolder;
  }
}

.activeTab {
  font-weight: bolder;
  color: #606DFF !important;
}

.listItem {
  position: relative;
}
