.container {
  flex-grow: 1;
  padding: 15px;
  padding-top: 0px;

  @media screen and (max-width: 499px) {
    padding: 0;
    padding-right: 15;
  }

  @media screen and (min-width: 1400px) {
    padding-right: 40px;
  }
}

.header, .mainHeader {
  font-size: 55px;
  color: white;
}

.mainHeader, .subHeader {
  font-family: Verdana, sans-serif;
  text-align: center;
}

.textContent {
  color: #9227b3;
  font-size: 20px;
  font-style: italic;

  @media screen and (max-width: 499px) {
    font-size: 16px;
  }
}

.textContent_Q {
  color: black;
  font-size: 20px;
  font-style: italic;

  @media screen and (max-width: 499px) {
    font-size: 16px;
  }
}

.mainHeader {
  margin-bottom: 0px;
  font-family: 'Staatliches', cursive;
  letter-spacing: 1px;
  color: #606DFF;

  @media screen and (max-width: 499px) {
    text-align: center;
    font-size: 23px;
  }
}

.subHeader {
  font-size: 24px;
  color: balck;
  margin-bottom: 0;

  @media screen and (max-width: 499px) {
    font-size: 19px;
  }
}

.buttonContainer {
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  height: auto;
}

.goBackButton {
  background: none;
  border: 3px solid #4ECCF1;
  padding: 10px 20px;
  border-radius: 40px;
  font-size: 18px;
  color: #4ECCF1;
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: #4ECCF1;
    color: white;
  }
}

.revealSection {
  padding-left: 24px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1400px) {
    padding-left: 0px;
  }

  @media screen and (max-width: 499px) {
    padding-left: 10px;
    padding-right: 10px;;
  }
}

.textContent_Bottom {
  min-height: 200px;

  @media screen and (max-width: 499px) {
    min-height: 150px;
    text-align: justify;
  }
}

.widget {
  min-height: 600px;
  height: auto;
  flex-grow: 1;
  max-width: 750px;
  border-radius: 25px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 30px 25px 30px;
  // background: rgb(95,95,95);
  // background: linear-gradient(122deg, rgba(95,95,95,1) 0%, rgba(4,7,37,1) 100%);
  box-shadow: 3px 3px 15px 3px rgba(0,0,0,0.26);

  @media screen and (max-width: 499px) {
    border-radius: 0;
    padding-left: 12px;
    padding-right: 12px;
    min-height: 450px;
  }
}

.subText {
  font-size: 20px;
  color: black;
  margin-top: 10px;
  text-align: center;

  @media screen and (max-width: 499px) {
    text-align: center;
  }
}

.listContainer {
  list-style: none;
  padding: 0;
  margin: 20px 0px;
}

.listItemContainer {
  margin: 10px 0px;
}

.faqCTA {
  margin-top: 10px;
  background: none;
  border: 1px solid #707070;
  padding: 18px;
  width: 100%;
  text-align: left;
  border-radius: 15px;
  font-size: 18px;
  color: black;
  letter-spacing: 1.2px;
  cursor: pointer;

  &:hover {
    border-color: #606DFF;
  }

  @media screen and (max-width: 499px) {
    border-radius: 10px;
    font-size: 16px;
  }
}

.number {
  color: #606DFF;
  font-weight: bold;
  font-size: 20px;

  @media screen and (max-width: 499px) {
    font-size: 18px;
  }
}

.backIcon {
  margin-left: 4px;
  top: 1px;
  font-size: 20px;
  position: relative;
}

.questionIcon {
  position: absolute;
  top: -20;
  right: 0;
  z-index: 2;
}

.iconWrapper {
  position: relative;
  top: -70px;
  right: -90px;
}

@media screen and (max-width: 570px) {

  .questionIcon {
    height: 150px;
    width: 150px;
  }

  .iconWrapper {
    right: 10;
  }

}

@media screen and (max-width: 540px) {
  .iconWrapper {
    display: none;
  }
}

.cowContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.cow {
  max-height: 300px;
}