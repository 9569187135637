
.volumeIcon {
  border: 3px solid black;
  padding: 5px 10px;
  border-radius: 50px;
  margin-left: 10px;
  margin-right: 10px;
  top: 5px;
  position: relative;
  color: black;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: #293D43;
    border-color: #293D43;
  }
}

.default-p {
  color: #434343;
  font-size: 30px;
  margin: 0px;
  font-weight: lighter;
}

.strongText {
  color: #606DFF;
  font-family: 'Staatliches', cursive;
  font-weight: bolder;
  font-size: 55px;
  margin: 0px;

  @media screen and (max-width: 499px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 20px;
  }
}

.subtext {
  margin: -10px 0px 30px 0px;
  color: #606DFF;
  font-weight: normal;
  font-size: 30px;

  @media screen and (max-width: 499px) {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}

.textBody {
  color: #434343;
  font-size: 24px;
  line-height: 1.3;
  font-weight: lighter;

  @media screen and (max-width: 499px) {
    font-size: 20px;
    text-align: justify;
  }
}

.ufoImg {
  max-height: 320px;
  margin-top: -90px;
  position: relative;
  left: -180px;
  margin-bottom: -180px;
  z-index: 2;
  animation: hover-animation 3s infinite alternate ease-in-out;
}

@keyframes hover-animation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-13px);
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 1100px) {
  .ufoImg {
    margin-top: -20px;
    margin-bottom: -140px;
    left: 50px;
    max-height: 260px;
  }

  @keyframes hover-animation {
    0% {
      transform: translateY(0) rotate(25deg);
    }
    50% {
      transform: translateY(-13px) rotate(25deg);
    }
    100% {
      transform: translateY(0) rotate(25deg);
    }
  }
}
