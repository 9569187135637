.footer-container {
  margin-top: 30px;
  text-align: center;
  position: relative;
  padding-top: 20px;
  min-height: 50px;
  height: 100%;
  padding-bottom: 10px;
  border-top: thin solid rgb(32, 31, 31);
  color: white;
  background: rgb(69, 74, 80);
  p {
    text-align: center;
    width: 100%;
  }
}

.link {
  margin: 8px;
  margin-top: 0px;
  font-size: 40px;
  color: #ffffff;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: #cf82eb;
  }
}