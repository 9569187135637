.mainContainer {
  // background: rgb(110,154,177);
  // background: linear-gradient(129deg, rgba(110,154,177,1) 0%, rgba(140,180,137,1) 100%);
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media screen and (max-width: 499px) {
    padding: 0px;
  }
}