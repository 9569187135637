.container {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  padding-top: 20px;
  // min-height: 680px;

  @media screen and (max-width: 499px) {
    padding: 0px;
  }
}

.projectsContainer {
  max-width: 1400px;
  width: 100%;
  margin: auto;
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center; 

  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 850px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.projectsSeparater {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;

  h3 {
    color: #606DFF;
    font-family: 'Staatliches', cursive;
    font-weight: bolder;
    font-size: 40px;
    line-height: 20px;
  }
}

.techStackTags {
  display: flex;
  padding-bottom: 10px;
  flex-wrap: wrap;
}

.boldMe {
  font-weight: bold;
  color: #434343;
}

.tagItem {
  color: white;
  background-color: rgb(252, 106, 43);
  border-radius: 20px;
  margin-right: 8px;
  margin-top: 10px;
  padding: 4px 14px 6px 14px;
  font-weight: bold;
  font-size: 0.8rem;
}

.icon {
  color: white;
  font-size: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 152px;
  left: 5px;
  position: relative;
}

.projectCardContainer {
  max-width: 450px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  animation-delay: 0.2s;
  margin-top: 50px !important;
  margin-bottom: 30px !important;
  box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.20);
  -webkit-box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.20);
  -moz-box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.20);

  &:nth-child(2) {
    animation-delay: 0.4s;
  }

  &:nth-child(3) {
    animation-delay: 0.6s;
  }

  &:nth-child(4) {
    animation-delay: 0.8s;
  }

  &:nth-child(5) {
    animation-delay: 1s;
  }
}

.disabled {
  pointer-events: none;
}

.card {
  margin: 14px;
  height: 250px;
  background-size: cover;
  background-position: center top;
  position: relative;
}

.header {
  padding: 2px 0px;
  width: 245px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: -40px;
  background: rgb(179,85,172);
  background: linear-gradient(122deg, rgba(179,85,172,1) 0%, rgba(23,90,155,1) 100%);
  -webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.33); 
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.33);

  h3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
    font-size: 22px;
    color: white;
  }
  &:nth-child(2) {
    width: 150px;
  }
}

.button {
  border: thin solid black;
  padding: 10px 17px;
  border-radius: 5px;
  margin-right: 10px;
}

.projectLinks {
  padding-top: 10px;
  display: flex;
  font-weight: bold;

  a {
    color: black;
    text-decoration: none;
    pointer-events: all;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}

.button__info {
  color: #fff !important;
  background-color: #0093a9;
  border-color: #0093a9;
  transition: 0.2s;

  &:hover {
    background-color: #189bb0;
    border-color: #189bb0;
  }
}

.button__dark {
  color: #fff !important;
  background-color: #343a40;
  border-color: #343a40;
  transition: 0.2s;

  &:hover {
    background-color: #ffffff;
    border-color: #343a40;
    color: #343a40 !important;
  }
}

.infoContainer {
  padding-bottom: 20px;
}

.desc {
  padding: 20px;
  padding-top: 0px;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    color: #434343;
    margin: 0;
  }

  .info {
    height: 120px;
    font-size: 18px;

    @media screen and (max-width: 900px) {
      height: auto;
    }
  }
}
