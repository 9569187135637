.container {
  display: flex;
  flex-direction: row;
  align-items: top;
  max-width: 1600px;
  width: 100%;
  align-self: center;
  padding-top: 20px;
  min-height: 82vh;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 499px) {
    padding: 0px 10px !important;
  }
}

.headerIcon {
  margin-right: 14px;
}

.skillCard {
  padding: 10px 20px;
  flex-grow: 1;
  width: 100%;
  margin: 20px;
  height: 520px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.20);
  -webkit-box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.20);
  -moz-box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.20);
  animation-delay: 0.2;

  &:nth-child(1) {
    @media screen and (max-width: 499px) {
      margin-top: 60px;
    }
  }

  &:nth-child(2) {
    animation-delay: 0.4s;
  } 

  &:nth-child(3) {
    animation-delay: 0.6s;
  }

  @media screen and (max-width: 1200px) {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    &:nth-child(2) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

  @media screen and (max-width: 499px) {
    width: 100%;
    border-radius: 0px;
    padding: 0px;
  }
}

.listContainer {
  list-style: square;
  padding-left: 24px;
  margin-top: 45px;

  @media screen and (max-width: 499px) {
    padding-left: 40px;
  }
}

.listContent {
  font-size: 20px;
  color: black;

  @media screen and (max-width: 499px) {
    font-size: 16px;
  }
}

.headerCardContainer {
  padding: 2px 0px;
  width: 245px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: -40px;
  background: rgb(179,85,172);
  background: linear-gradient(122deg, rgba(179,85,172,1) 0%, rgba(23,90,155,1) 100%);
  -webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.33); 
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.33);

  h3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
    font-size: 22px;
    color: white;
  }
}

