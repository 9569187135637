iframe {
  width: 100%;
  min-height: 460px;
  height: auto;
}

.header {
  color: #606DFF;
  font-family: 'Staatliches', cursive;
  font-weight: bolder;
  font-size: 40px;
}

.iFrameContainer {
  display: flex;
  flex-grow: 1;
  margin-bottom: 24px;
  box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.20);
  -webkit-box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.20);
  -moz-box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.20);
}