.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  transition: 0.2s;
  box-shadow: 0px 0px 5px 0px rgba(122,104,202,0.8);

  @media screen and (max-width: 500px) {
    padding-top: 20px;
  }
  
  @media screen and (max-width: 501px) {
    padding-bottom: 0px;
  }
}

.noPadding {
  margin: 0;
}

.navbar-ul {
  margin: 0;
  padding: 0;
  border-radius: 50px;
  width: 400px;
  flex-basis: auto;
  display: flex;
  flex-direction: row;
  align-self: center;
  list-style: none;
  opacity: 0.85;
  min-height: 0px;

  li {
    padding: 15px;
    flex-grow: 1;
    text-align: center;
  }

  @media screen and (max-width: 600px) {
    border-radius: 0px;
    width: 100%;
  }
}

.navbar-ul {
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.active {
  .hamburgerMenuWrapper {
    box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.8);
    border: 2px solid rgb(232, 227, 255);
  }
}

.hamburgerMenuWrapper {
  border-radius: 50px;
  height: 40px;
  width: 40px;
  padding: 10px;
  display: none;
  margin-bottom: 20px;
  box-shadow: 0px 0px 5px 0px rgba(122, 104, 202, 0.8);

  button {
    margin: auto !important;
    padding: 0;
  }

  @media screen and (max-width: 500px) {
    display: flex;
    margin: auto;
  }

  button {
    background: none;
    border: none;
    margin: 0;
    font-size: 30px;
    color: #6557A4;
    cursor: pointer;
  }
}

.buttonItem {
  color: rgb(46, 46, 46);
  border: none;
  padding: 10px;
  font-size: 24px;
  background: none;
  cursor: pointer;
  font-family: 'Staatliches', cursive;
}

.activeLink {
  color: #606DFF;
}